<template>
  <div class="MappingGroups" style="height: calc(100vh - 130px)">
    <span class="row-count" style="border-bottom: 1px solid black);">
      Total manager groups: {{ rowCount }}
    </span>

    <DataView :value="namedMappingGroups" layout="grid">
      <template #header>
        <div class="p-d-flex p-jc-between p-ai-center">
          Manager Group Templates
        </div>
      </template>
      <template #grid="slotProps">
        <div class="p-col-3 p-p-3" style="position: relative">
          <div class="mapping-group-item">
            <div class="p-mb-0 p-text-bold">{{ slotProps.data.name }}</div>
            <div class="p-mb-2 p-text-light">
              {{ slotProps.data.description }}
            </div>
            <div>
              <div
                v-for="member in slotProps.data.members"
                class="p-grid p-d-flex p-ai-center p-jc-between p-my-1"
              >
                <div class="p-col">
                  {{ member.first_name }}
                  {{ member.last_name }}
                </div>
                <div class="p-col-fixed" style="width: 3rem">
                  <country-flag
                    :country="
                      offices.filter((item) => member.office_id === item.id)[0]
                        .code
                    "
                    size="small"
                  />
                </div>
                <div class="p-col">
                  {{ getRoleName(member.role_id) }}
                </div>
                <div class="p-col-fixed">
                  {{ member.split | percentage }}
                </div>
              </div>
            </div>
          </div>
          <!--          <router-link-->
          <!--            :to="'/manage/mapping-groups/' + slotProps.data.id"-->
          <!--            tag="button"-->
          <!--            class="mapping-group-button-layer"-->
          <!--          >-->
          <!--            <Button-->
          <!--              class="p-button-rounded p-button-main p-button-sm manage-list-view-button"-->
          <!--              label="View"-->
          <!--            />-->
          <!--          </router-link>-->
        </div>
      </template>
    </DataView>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import {
  readManagerRoles,
  readManagers,
  readMappingGroups,
  readOffices,
  readTeams,
} from "@/store/main/getters";
import {
  dispatchListManagers,
  dispatchListMappingGroups,
  dispatchListOffices,
} from "@/store/main/actions";
import DataView from "primevue/dataview/DataView";
import { readManager } from "@/store/manager/getters";

@Component({
  components: {
    DataView: DataView,
  },
})
export default class MappingGroups extends Vue {
  public search = "";
  public filters = {};
  public loading = true;
  public rowCount = 0;
  public namedMappingGroups = [];

  get managerRoles() {
    return readManagerRoles(this.$store);
  }

  get offices() {
    return readOffices(this.$store);
  }

  public updateRowCount(e) {
    this.rowCount = e.filteredValue.length;
  }

  get mappingGroups() {
    return readMappingGroups(this.$store);
  }

  get managers() {
    return readManagers(this.$store);
  }

  public getRoleName(roleId: number) {
    if (roleId) {
      const role = this.managerRoles.filter((item) => item.id === roleId)[0];
      if (role) {
        return role.name;
      }
      return "";
    }
    return "";
  }

  public async mounted() {
    window["analytics"]["page"]("/manage/mapping-groups");
    if (!this.managers.length) {
      await dispatchListManagers(this.$store);
    }
    if (!this.offices.length) {
      await dispatchListOffices(this.$store);
    }
    await dispatchListMappingGroups(this.$store);
    this.namedMappingGroups = this.mappingGroups.map((mappingGroup) => {
      const data = mappingGroup;
      data.members = mappingGroup.template.members.map((member) => {
        const filtered = this.managers.filter(
          (manager) => manager.id == member.manager_id
        );
        const manager = filtered.length ? filtered[0] : {};
        return { ...member, ...manager };
      });
      return data;
    });
    this.loading = false;
    this.rowCount = this.mappingGroups.length;
  }
}
</script>

<style>
.MappingGroups {
  position: relative;
  height: calc(100vh - 130px);
}

.mapping-group-button-layer {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(250, 250, 250, 0.6);
  box-shadow: inset 0 0 50px white;
}

.mapping-group-item:hover + .mapping-group-button-layer,
.mapping-group-button-layer:hover {
  display: inline-block;
}

.mapping-group-item {
  background-color: var(--pale-grey-three);
  height: 100%;
  width: 100%;
  padding: 1rem;
  /*cursor: pointer;*/
}
</style>
